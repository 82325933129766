import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { clearStorage } from "../form/persistence"
import { hidePage, showPage } from "../ui/page"

export const showConfirmation = (html) => {
  elements.adf.classList.add(classes.hiddenClass)
  hidePage()
  // Confirmation screen has its own banner
  document.querySelector('.banner-brand').classList.add(classes.hiddenClass)
  // Display confirmation HTML
  if (html) {
    elements.confirmation.innerHTML = html
  }
  // Wait a bit so donor can see that gift is being processed
  setTimeout(() => {
    const pledgeType = document.getElementById('jsPledgeType')
    const paymentOption = document.getElementById('jsPaymentOption')
    const standardFrequency = document.getElementById('standardFrequency')
    const payrollFrequency = document.getElementById('jsPayrollFrequency')
    const payrollSummary = document.getElementById('payrollSummary')

    if (pledgeType && paymentOption) {
      if (paymentOption.textContent === 'Pledge') {
        pledgeType.textContent = document.getElementById('monthlyPayroll').checked ? '(this is a recurring gift)' : '(this is a pledge)'
        paymentOption.textContent = document.getElementById('jsPayment').textContent
        // The payroll rules don't fit into the standard merge fields, so display summary from donation page
        if (standardFrequency && payrollFrequency && payrollSummary) {
          standardFrequency.classList.add(classes.hiddenClass)
          payrollFrequency.innerHTML = payrollSummary.innerHTML
          // Remove .alert formatting
          payrollFrequency.querySelectorAll('.payroll-summary').forEach(el => {
            el.classList.remove('alert', 'alert-dark')
          })
        }
      }
    }
    // jsNotifyDisplayName only available with tributes
    if (document.getElementById('jsNotifyDisplayName')) {
      document.getElementById('jsNotifyDisplayName').textContent = document.getElementById('notifyDisplayName').value

      if (document.getElementById('jsNotifyDisplayName').textContent !== '' && document.getElementById('notifySummary')) {
        document.getElementById('notifySummary').classList.remove(classes.hiddenClass)
      }
    }
    if (elements.isMatching.checked) {
      document.getElementById('matchingGiftInfo').classList.remove(classes.hiddenClass)
      document.getElementById('matchingGiftEmployer').textContent = document.getElementById('employer').value
    }
    elements.confirmation.classList.remove(classes.hiddenClass)
    showPage()
  }, 1000)
  // Remove saved data
  clearStorage(true)
}