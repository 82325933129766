import { classes } from "../variables/classes"
import { events } from "../variables/events"
import { params } from "../variables/params"
import { state } from "../variables/state"
import { fundCategories, getFund, bindFundEvents } from "./funds"
import { updateGiftRowIndex, updateGiftAmountTotal } from "./gifts"
import { saveForm } from "../form/persistence"
import { fv, addAmountValidator } from "../form/validation"
import { numToFloat } from "../lib/utils"

export const addPreSelectedGiftRow = (gift) => {
  const giftFundCategory = fundCategories[gift.fund.categories[0]] ? fundCategories[gift.fund.categories[0]] : ''
  const giftFundUnit = gift.fund.categories[1] ? gift.fund.categories[1] : ''
  const giftFundArea = gift.fund.categories[2] ? gift.fund.categories[2] : ''
  const giftFundId = gift.fund.guid
  const giftFundText = gift.fund.name
  const giftAmount = gift.amount === 0 ? '' : numToFloat(gift.amount, 2)
  const editStatus = gift.edit
  const removeStatus = gift.remove
  const gifts = document.querySelectorAll('.gifts')
  let giftRowIndex = updateGiftRowIndex()

  const increaseOnly = (e, minAmount) => {
    if (e.target.value < minAmount || isNaN(parseFloat(e.target.value))) {
      e.target.value = minAmount
    }
  }

  gifts.forEach(function(el, index) {
    let clone = document.getElementById('giftRowTemplate').content.cloneNode(true)
    const amountField = clone.querySelector('.form-control')
    const editButton = clone.querySelector('.btn-edit')
    const removeButton = clone.querySelector('.btn-remove')

    clone.firstElementChild.setAttribute('data-row-index', giftRowIndex)
    clone.querySelector('.gift-name').textContent = giftFundText
    amountField.value = giftAmount
    amountField.name = `amount-${giftRowIndex}-${index}`
    clone.querySelector('.gift-category').value = giftFundCategory
    clone.querySelector('.gift-category').name = `gift-${giftRowIndex}-${index}`
    clone.querySelector('.gift-unit').value = giftFundUnit
    clone.querySelector('.gift-unit').name = `gift-unit-${giftRowIndex}-${index}`
    clone.querySelector('.gift-area').value = giftFundArea
    clone.querySelector('.gift-area').name = `gift-area-${giftRowIndex}-${index}`
    clone.querySelector('.gift-fund').value = giftFundId
    clone.querySelector('.gift-fund').name = `gift-fund-${giftRowIndex}-${index}`
    clone.querySelector('.fund-search').value = giftFundCategory === '' ? true : false
    clone.querySelector('.fund-search').name = `fund-search-${giftRowIndex}-${index}`
    clone.querySelector('.fund-specified').value = false
    clone.querySelector('.fund-specified').name = `fund-specified-${giftRowIndex}-${index}`

    if (editStatus === '1') {
      editButton.classList.remove(classes.hiddenClass)
      amountField.addEventListener('change', e => {
        increaseOnly(e, giftAmount)
      })
    } else if (editStatus === '2') {
      editButton.classList.add(classes.hiddenClass)
      amountField.disabled = true
    } else if (editStatus === '3') {
      editButton.classList.add(classes.hiddenClass)
    }
    if (removeStatus === '1') {
      removeButton.classList.add(classes.hiddenClass)
    }
    // Add row
    el.querySelector('.list-group').append(clone)
    // Dynamic validation for amount field
    addAmountValidator(`amount-${giftRowIndex}-${index}`)
  })
  updateGiftAmountTotal()
  bindFundEvents()
  // Save form state
  saveForm(1)
  // Go to second panel via validator
  if (!params.quickForm) {
    fv.plugins.wizard.currentStep = 1
    events.panelValid.index = 1
    window.dispatchEvent(events.panelValid)
  }
}

export const addPreselectedGift = (fundId, amount, edit, remove) => {
  const fund = getFund(fundId)
  let gift = {}

  if (fund) {
    gift.fund = fund
    gift.amount = Number(amount)
    gift.edit = edit
    gift.remove = remove
    addPreSelectedGiftRow(gift)
    return true
  } else {
    return false
  }
}

export const getPreSelectedData = () => {
  const preSelectedDesignation = document.getElementById('jsPreselectedFund')
  if (preSelectedDesignation) {
    return JSON.parse(preSelectedDesignation.dataset.fund)
  }
}

export const togglePreSelectedFundOnly = (mode) => {
  if (mode === 'show') {
    document.getElementById('userSelected').classList.add(classes.hiddenClass)
    document.getElementById('preSelected').classList.remove(classes.hiddenClass)
  } else {
    document.getElementById('preSelected').classList.add(classes.hiddenClass)
    document.getElementById('userSelected').classList.remove(classes.hiddenClass)
  }
}

const showPreSelectedFund = (fundId, editStatus, removeStatus) => {
  const fund = getFund(fundId)

  if (fund) {
    const preSelectedFund = document.getElementById('jsPreselectedFund')

    if (preSelectedFund) {
      preSelectedFund.textContent = fund.name
      fund.editStatus = '3'
      fund.removeStatus = '1'
      preSelectedFund.dataset.fund = JSON.stringify(fund)
      togglePreSelectedFundOnly('show')
    }
  }
}

export const maybePreselectFunds = () => {
  const searchParams = new URLSearchParams(window.location.search)

  if (params.preselectedFund && !state.restore) {
    const preSelectedFunds = searchParams.getAll('f')
    const preSelectedAmounts = searchParams.getAll('amount')
    const editStatus = searchParams.get('e')
    const removeStatus = searchParams.get('r')
    let giftCount = 0

    if (preSelectedFunds.length > 0) {
      if (preSelectedFunds.length === preSelectedAmounts.length) {
        preSelectedFunds.forEach((fundId, index) => {
          if (addPreselectedGift(fundId, preSelectedAmounts[index], editStatus, removeStatus)) {
            giftCount++
          }
        })
      } else if (preSelectedFunds.length === 1 && preSelectedAmounts.length === 0) {
        state.preselectedFundOnly = true
        showPreSelectedFund(preSelectedFunds[0], editStatus, removeStatus)
      }
    }
  }
}