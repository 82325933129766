import { init as charCount } from "./ui/charCount"
import { initCollapse, initTriggers } from "./ui/collapse"
import { init as inputMask } from "./ui/inputMask"
// import { init as mapsAutocomplete } from "./ui/mapsAutocomplete";
import { init as nav } from "./ui/nav"
import { init as payrollForm } from "./ui/payrollForm"
import { init as quickForm } from "./ui/quickForm"
import { params } from "./variables/params"

const options = window.ZU_ADF.options || {}

export const init = () => {
  if (params.quickForm === '1') {
    quickForm()
  } else {
    if (options.enablePayroll) {
      payrollForm()
    }
    nav()
  }
  initCollapse()
  initTriggers()
  charCount()
  inputMask()
  // mapsAutocomplete();
}