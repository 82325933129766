import dayjs from 'dayjs'
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrAfter)

import { classes } from '../variables/classes'
import { constants } from '../variables/constants'
import { elements } from '../variables/elements'
import { params } from '../variables/params'
// import { editorContent } from './getEditorContent'
import { partId } from '../api'
import { appealId } from './getAppeals'
import { processPayment } from './processPayment'
import { processPledge } from './processPledge'
import * as utils from '../lib/utils'

export let donation = null

const adfConstants = window.ZU_ADF.constants || {}
const adfGuids = window.adfGuids || {}
const options = window.ZU_ADF.options || {}

export const processDonation = () => {
  const getDonation = new Promise((resolve, reject) => {
    const paymentMethod = utils.getPaymentMethod()
    let writeIn = false
    let writeInName = ''

    const addRevenueAttributes = () => {
      let attributes = []
      let writeInAttribute = {}

      const createAttribute = (el, val) => {
        let attribute = {}

        attribute.AttributeId = el.dataset.guid
        attribute.Value = el.dataset.attributeValue ? el.dataset.attributeValue : val || el.value
        return attribute
      }

      document.querySelectorAll('.is-attribute').forEach(el => {
        const closestCollapse = el.closest('.collapse')
        let isActive = false

        const getStatus = el => {
          return el.type.toLowerCase() === 'checkbox' || el.type.toLowerCase() === 'radio' ? el.checked : el.value !== ''
        }

        if (closestCollapse) {
          if (closestCollapse.classList.contains(classes.collapseShowClass)) {
            isActive = getStatus(el)
          }
        } else {
          isActive = getStatus(el)
        }

        if (isActive) {
          attributes.push(createAttribute(el))
        }
      })

      document.querySelectorAll('.is-attribute-group').forEach(el => {
        const closestCollapse = el.closest('.collapse')
        let groupValue = null
        let attribute = {}

        const getGroupValue = () => {
          let groupValue = []

          el.querySelectorAll('input[type=checkbox]').forEach(el => {
            if (el.checked) {
              groupValue.push(el.value)
            }
          })
          return groupValue.length > 0 ? groupValue.join('|') : null
        }

        if (closestCollapse) {
          if (closestCollapse.classList.contains(classes.collapseShowClass)) {
            groupValue = getGroupValue(el)
          }
        } else {
          groupValue = getGroupValue(el)
        }

        if (groupValue) {
          attribute.AttributeId = el.dataset.guid
          attribute.Value = groupValue
          attributes.push(attribute)
        }
      })

      if (writeIn) {
        writeInAttribute.AttributeId = adfGuids.defaultDesignation
        writeInAttribute.Value = writeInName
        attributes.push(writeInAttribute)
      }
      if (attributes.length > 0) {
        donation.Gift.Attributes = attributes
      }
    }

    const addTribute = () => {
      if (elements.isTribute.checked) {
        donation.Gift.Tribute = {
          TributeDefinition: {},
          Acknowledgee: {},
        }
        let tributeeInfo = {
          AttributeId: guids.tributeTributee,
          Value: '',
        }
        let acknowledgeeInfo = {
          AttributeId: guids.tributeInfoAcknowledgee,
          Value: '',
        }
        donation.Gift.Attributes = donation.Gift.Attributes || []

        // Set TributeDefinition
        donation.Gift.Tribute.TributeDefinition.FirstName = document.getElementById('honoreeFirstName').value
        donation.Gift.Tribute.TributeDefinition.LastName = document.getElementById('honoreeLastName').value
        donation.Gift.Tribute.TributeDefinition.Type = document.querySelector('[name="tribute-type"]:checked').value
        donation.Gift.Tribute.TributeDefinition.Description = state.isTribute ? trim(document.getElementById('tributeName').textContent) : `${donation.Gift.Tribute.TributeDefinition.Type} ${donation.Gift.Tribute.TributeDefinition.FirstName} ${donation.Gift.Tribute.TributeDefinition.LastName}`

        // Add tributee name revenue attribute
        tributeeInfo.Value = `${donation.Gift.Tribute.TributeDefinition.FirstName} ${donation.Gift.Tribute.TributeDefinition.LastName}`
        donation.Gift.Attributes.push(tributeeInfo)

        // Set acknowledgee fields
        if (document.getElementById('notify').checked) {
          donation.Gift.Tribute.Acknowledgee.FirstName = document.getElementById('notifyFirstName').value
          donation.Gift.Tribute.Acknowledgee.LastName = document.getElementById('notifyLastName').value
          donation.Gift.Tribute.Acknowledgee.AddressLines = document.getElementById('notifyAddress').value
          donation.Gift.Tribute.Acknowledgee.City = document.getElementById('notifyCity').value
          donation.Gift.Tribute.Acknowledgee.State = document.getElementById('notifyState').value
          donation.Gift.Tribute.Acknowledgee.PostalCode = document.getElementById('notifyZip').value
          acknowledgeeInfo.Value = `${donation.Gift.Tribute.Acknowledgee.FirstName} ${donation.Gift.Tribute.Acknowledgee.LastName}/${donation.Gift.Tribute.Acknowledgee.AddressLines}, ${donation.Gift.Tribute.Acknowledgee.City}, ${donation.Gift.Tribute.Acknowledgee.State} ${donation.Gift.Tribute.Acknowledgee.PostalCode}/${donation.Gift.Tribute.TributeDefinition.Description}`
          // Add acknowledgee info revenue attribute
          if (guids.tributeInfoAcknowledgee) {
            donation.Gift.Attributes.push(acknowledgeeInfo)
          }
        } else {
          donation.Gift.Tribute.Acknowledgee.FirstName = '~'
          donation.Gift.Tribute.Acknowledgee.LastName = '~'
          donation.Gift.Tribute.Acknowledgee.AddressLines = '~'
          donation.Gift.Tribute.Acknowledgee.City = '~'
          donation.Gift.Tribute.Acknowledgee.State = '~'
          donation.Gift.Tribute.Acknowledgee.PostalCode = '~'
        }
        donation.Gift.Tribute.Acknowledgee.Country = 'US'
      }
    }

    const addRecurring = () => {
      const frequency = document.querySelector('[name="gift-recurrence"]:checked').value

      if (frequency !== '') {
        donation.Gift.Recurrence = {
          DayOfMonth: new Date().getDate(),
          StartDate: new Date(),
          Frequency: frequency,
        }
        // Annual requires month
        if (frequency === '4') {
          donation.Gift.Recurrence.Month = new Date().getMonth() + 1
        }
        // Opens Card Not Present checkout
        donation.Gift.Recurrence.ProcessNow = true
      }
    }

    const addPayroll = () => {
      // const getNextBiWeeklyPayday = (seedDate, submitDate) => {
      //   const daysBetweenPayday = 14
      //   let payday = seedDate
      //   let paydayFound = false

      //   while (!paydayFound) {
      //     if (dayjs(payday).isSameOrAfter(submitDate, 'day')) {
      //       paydayFound = true
      //       payday = dayjs(payday).diff(submitDate, 'day') === 0 ? dayjs(payday).add(daysBetweenPayday, 'day') : (payday = dayjs(payday))
      //     }
      //     payday = dayjs(payday).add(daysBetweenPayday, 'day')
      //   }
      //   return payday
      // }

      // const getNextMonthlyPayday = submitDate => {
      //   const nextScheduledMonth = dayjs().date() <= 20 ? dayjs(submitDate).add(1, 'month').month() : dayjs(submitDate).add(2, 'month').month()
      //   const firstDayOfMonth = dayjs().month(nextScheduledMonth).startOf('month')

      //   return dayjs(firstDayOfMonth)
      // }

      const frequency = document.querySelector('[name="gift-recurrence-payroll"]:checked').value

      // Set pledge fields
      donation.Gift.PledgeInstallment = {}
      // frequency === 1 is a bi-weekly pledge
      donation.Gift.PledgeInstallment.NumberOfInstallments = frequency === '' ? 1 : (frequency === '1' ? elements.numInstallments.value : constants.numRecurringInstallments)
      donation.Gift.PledgeInstallment.InstallmentAmount = frequency === '1' ? Number(utils.getTotalGiftAmount() / donation.Gift.PledgeInstallment.NumberOfInstallments).toFixed(2) : utils.getTotalGiftAmount()

      // Set recurrence fields if not one-time gift
      if (frequency !== '') {
        // const seedDate = dayjs(options.employeePayDate)
        const today = dayjs()
        // const nextPayday = frequency === '1' ? getNextBiWeeklyPayday(seedDate, today) : getNextMonthlyPayday(today)

        donation.Gift.Recurrence = {}

        // Removing payroll start date calculations
        // donation.Gift.Recurrence.DayOfMonth = dayjs(nextPayday).date()
        // donation.Gift.Recurrence.StartDate = dayjs(nextPayday).toDate()

        // Use today for start date or preset date if in campaign
        if (document.body.classList.contains('campaign-active') && adfConstants.employeeCampaignPledgeProcessDate) {
          donation.Gift.Recurrence.DayOfMonth = dayjs(adfConstants.employeeCampaignPledgeProcessDate).date()
          donation.Gift.Recurrence.StartDate = dayjs(adfConstants.employeeCampaignPledgeProcessDate).toDate()
        } else {
          donation.Gift.Recurrence.DayOfMonth = today.date()
          donation.Gift.Recurrence.StartDate = today.toDate()
        }
        donation.Gift.Recurrence.Frequency = 2
        // Opens Store Card checkout
        donation.Gift.Recurrence.ProcessNow = false
      }
      // Add type of payroll deduction (one-time, b-weekly, recurring)
      if (typeof codes !== 'undefined') {
        donation.Gift.Attributes = donation.Gift.Attributes || []
        donation.Gift.Attributes.push({
          AttributeId: guids.payrollDeductionCode,
          Value: frequency === '' || frequency === '1' ? codes.biWeeklyPayroll : codes.recurringPayroll,
        })
      }
    }

    const addDesignations = () => {
      // const numberOfInstallments = typeof donation.Gift.PledgeInstallment !== 'undefined' ? donation.Gift.PledgeInstallment.NumberOfInstallments : 1
      let designations = []
      let gift

      document.querySelectorAll('#gifts .gift').forEach(fund => {
        gift = {}
        // gift.Amount = parseFloat(fund.querySelector('.amount').value) * parseInt(numberOfInstallments);
        gift.Amount = utils.stripCommas(fund.querySelector('[name^=amount]').value)
        gift.DesignationId = fund.querySelector('.gift-fund').value
        designations.push(gift)

        if (options.enableWriteIn) {
          if (fund.querySelector('.fund-specified').value === 'true') {
            writeIn = true
            writeInName = fund.querySelector('.gift-name').textContent
          }
        }
      })

      if (designations.length > 0) {
        donation.Gift.Designations = designations
      }
    }

    donation = {
      Donor: {
        Title: document.getElementById('title').value,
        FirstName: document.getElementById('firstName').value,
        LastName: document.getElementById('lastName').value,
        EmailAddress: document.getElementById('email').value,
        Phone: document.getElementById('phone').value,
        Address: {
          StreetAddress: document.getElementById('address').value,
          City: document.getElementById('city').value,
          State: elements.state.value || ' ',
          Country: elements.country ? utils.getSelectedOptionText(elements.country) : 'United States',
          PostalCode: document.getElementById('zip').value,
        },
      },
      Gift: {
        PaymentMethod: paymentMethod,
        IsAnonymous: options.enableAnonymous ? document.getElementById('isAnonymous').checked : false,
        IsCorporate: options.enableCorporate ? document.getElementById('isCorporate').checked : false,
      },
      Origin: {
        PageId: BLACKBAUD.api.pageInformation.pageId,
        PartId: partId,
      },
      PartId: partId,
      MerchantAccountId: window.checkoutData ? JSON.parse(checkoutData).MerchantAccountId : null
    }
    // Credit card payment needs BBSPReturnUri
    if (paymentMethod === '0') {
      donation.BBSPReturnUri = window.location.href
    }
    // Corporate gift
    if (donation.Gift.IsCorporate) {
      donation.Donor.OrganizationName = document.getElementById('organization').value
    }
    // Comments
    if (options.enableComments) {
      if (document.getElementById('additionalInfo').checked && document.getElementById('comments').value !== '') {
        donation.Gift.Comments = document.getElementById('comments').value
      }
    }
    // Source code
    if (params.scode) {
      donation.Gift.SourceCode = params.scode
    }
    // Appeal
    if (appealId) {
      console.log(appealId)
      donation.Origin.AppealId = appealId
    }
    addRevenueAttributes()
    if (options.enableTribute) {
      addTribute()
    }
    if (options.enableRecurring) {
      addRecurring()
    }
    if (options.enablePayroll && paymentMethod === '1') {
      addPayroll()
    }
    addDesignations()
    resolve()
  })

  getDonation.then(() => {
    if (utils.getPaymentMethod() === '0') {
      processPayment()
    } else {
      processPledge()
    }
  })
}
