import { donationService } from '../api'
import { showConfirmation } from "./showConfirmation"
import { donation } from "./createDonation"
// import { editorContent } from "./getEditorContent"
// import { publicKey } from "./getPublicKey"
import { hidePage, showPage } from "../ui/page"
import { getTotalGiftAmount } from "../lib/utils"

let bbcheckout = null
let checkoutModel = window.checkoutData ? JSON.parse(checkoutData) : null

export const initCheckout = () => {
  if (checkoutModel) {
    createCheckoutForm()
    bbcheckout = new BBCheckoutProcessor(checkoutFunctions(), checkoutModel.APIControllerName, checkoutModel.TokenId, '.confirmation')

    bbcheckout.Configuration.Data.Key = checkoutModel.PublicKey
    bbcheckout.Configuration.TransactionType = checkoutModel.TransactionType
    bbcheckout.Configuration.Data.ClientAppName = checkoutModel.ClientAppName
    bbcheckout.Configuration.Data.MerchantAccountId = checkoutModel.MerchantAccountId
    bbcheckout.Configuration.Data.IsEmailRequired = checkoutModel.IsEmailRequired
    bbcheckout.Configuration.Data.IsNameVisible = checkoutModel.IsNameVisible
    bbcheckout.Configuration.Data.PrimaryColor = checkoutModel.PrimaryColor
    bbcheckout.Configuration.Data.SecondaryColor = checkoutModel.SecondaryColor
    bbcheckout.Configuration.Data.FontFamily = checkoutModel.FontFamily
    bbcheckout.Configuration.Data.UseCaptcha = checkoutModel.UseCaptcha
    bbcheckout.Configuration.WorkflowType = checkoutModel.WorkFlowType
    bbcheckout.Configuration.HandleBrowserClosing = (checkoutModel.HandleBrowserClosing === true ? "True" : "False")
    bbcheckout.Configuration.APITokenID = checkoutModel.TokenId
    // You can add your own message to display on screen, after checkout pop-up close
    bbcheckout.Configuration.TempConfirmationHtml = "Thank you for your contribution, please wait while we process your transaction."
    bbcheckout.intializeCheckout()
  }
}

const checkoutFunctions = () => {
  let checkoutEvents = {
    checkoutComplete: (data) => {
      // Invoke after Payment will successfully Completed
      bbcheckout.postCheckoutFinish()
      showConfirmation()
    },
    checkoutError: (data) => {
      console.log(data)
    },
    checkoutExpired: () => {
      showPage()
    },
    checkoutReady: () => {
      hidePage()
    },
    browserClose: () => {
    },
    checkoutCancel: () => {
      showPage()
    },
    checkoutLoaded: () => {
      hidePage()
    }
  }
  return checkoutEvents
}

const createCheckoutForm = () => {
  const paymentForm = document.getElementById('paymentForm')

  if (!paymentForm) {
    const newForm = document.createElement('form')

    newForm.setAttribute('id', 'paymentForm')
    newForm.setAttribute('method', 'get')
    newForm.dataset.formtype = 'bbCheckout'
    newForm.setAttribute('data-disable-submit', 'false')
    newForm.novalidate = true
    document.body.appendChild(newForm)
  }
}

const makePayment = () => {
  const donor = donation.Donor

  bbcheckout.Configuration.Data.Amount = getTotalGiftAmount()
  bbcheckout.Configuration.Data.BillingAddressCity = donor.Address.City
  bbcheckout.Configuration.Data.BillingAddressCountry = donor.Address.Country
  bbcheckout.Configuration.Data.BillingAddressLine = donor.Address.StreetAddress
  bbcheckout.Configuration.Data.BillingAddressPostCode = donor.Address.PostalCode
  bbcheckout.Configuration.Data.BillingAddressState = donor.Address.State
  bbcheckout.Configuration.Data.BillingAddressEmail = donor.EmailAddress
  bbcheckout.Configuration.Data.BillingAddressFirstName = donor.FirstName
  bbcheckout.Configuration.Data.BillingAddressLastName = donor.LastName
  bbcheckout.Configuration.Data.Cardholder = `${donor.FirstName} ${donor.LastName}`
  bbcheckout.Configuration.Data.UseVisaCheckout = (donation.Gift && !donation.Gift.Recurrence)
  bbcheckout.Configuration.Data.UseMasterpass = (donation.Gift && !donation.Gift.Recurrence)
  bbcheckout.Configuration.Data.UseApplePay = (donation.Gift && !donation.Gift.Recurrence)
  bbcheckout.Configuration.TransactionType = bbcheckout.TransactionType.Card_Not_Present
  bbcheckout.Configuration.Data.CardToken = null

  if (donation.Gift && donation.Gift.Recurrence && !donation.Gift.Recurrence.ProcessNow) {
    bbcheckout.Configuration.Data.CardToken = checkoutModel.DataKey
    bbcheckout.Configuration.TransactionType = bbcheckout.TransactionType.Store_Card //Store card transactions
  } else if (donation.Gift && donation.Gift.Recurrence) {      //Set CardToken value only in case of recurring gifts.
    bbcheckout.Configuration.Data.CardToken = checkoutModel.DataKey
  }

  //Set Donor Info so that it will be passed to finish the transaction at the end.
  donation.DonationSource = bbcheckout.Configuration.DonationSource.ADF
  donation.Type = bbcheckout.Configuration.TranType.Donation
  bbcheckout.DonorInfo = donation
  bbcheckout.openCheckout()
}

export const processPayment = () => {
  const onSuccess = () => {
    makePayment()
  }

  const onFail = (errors) => {
    console.log(errors)
  }

  donationService.validateDonationRequest(donation, onSuccess, onFail)
}