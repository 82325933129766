export const initCollapse = () => {
  document.querySelectorAll('.collapse').forEach(el => {
    el.addEventListener('hidden.bs.collapse', e => {
      clearFields(e.target)
    })
  })
}

export const initTriggers = () => {
  document.querySelectorAll('[data-trigger]').forEach(el => {
    el.addEventListener('click', e => {
      const triggers = el.dataset.trigger.split(',')

      triggers.forEach(trigger => {
        document.getElementById(trigger).click()
      })
    })
  })
  document.querySelectorAll('[data-trigger-collapse]').forEach(el => {
    el.addEventListener('click', e => {
      const triggers = el.dataset.triggerCollapse.split(',')

      triggers.forEach(trigger => {
        if (document.getElementById(trigger).checked) {
          document.getElementById(trigger).click()
        }
      })
    })
  })
}

export const hideCollapse = (collapse) => {
  collapse.hide()
}

export const showCollapse = (collapse) => {
  collapse.show()
}

const clearFields = (target) => {
  // Text fields
  target.querySelectorAll('.form-control, .form-select').forEach(el => {
    el.value = ''
  })
  // Checkboxes
  target.querySelectorAll('input[type="checkbox"]').forEach(el => {
    if (el.checked) {
      el.click()
    }
  })
  // Radios
  target.querySelectorAll('input[type="radio"]').forEach(el => {
    el.checked = false
    // Tick the defaults
    if (document.querySelector(`[name=${el.name}][checked]`)) {
      document.querySelector(`[name=${el.name}][checked]`).checked = true
    }
  })
}