import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { options } from "../variables/options"
import { getSelectedOptionText, getISO } from "../lib/utils"
import { getGiftFrequency } from "./giftRecurrence"

export const updateSummary = () => {
  const isInternational = options.internationalFields
    ? elements.intlFieldsTab.classList.contains(classes.activeClass)
    : false

  document.getElementById("jsFrequency").textContent = getGiftFrequency()
  document.getElementById("jsPayment").textContent = document.querySelector(
    '[name="payment-method"]:checked'
  ).nextElementSibling.textContent
  document.getElementById("jsTitle").textContent =
    document.getElementById("title").value
  document.getElementById("jsFirstName").textContent =
    document.getElementById("firstName").value
  document.getElementById("jsLastName").textContent =
    document.getElementById("lastName").value
  document.getElementById("jsAddress").textContent = isInternational
    ? document.getElementById("intlAddress").value
    : document.getElementById("address").value
  document.getElementById("jsCity").textContent = isInternational
    ? document.getElementById("intlCity").value
    : document.getElementById("city").value
  // document.getElementById('jsState').textContent = isInternational ? document.getElementById('province').value || getSelectedOptionText(document.getElementById('provinceSelect')) : getISO(document.getElementById('state'));
  document.getElementById("jsState").textContent = isInternational
    ? document.getElementById("province").value ||
    getSelectedOptionText(document.getElementById("provinceSelect"))
    : document.getElementById("state").value
  document.getElementById("jsZip").textContent = isInternational
    ? document.getElementById("intlZip").value
    : document.getElementById("zip").value
  document.getElementById("jsCountry").textContent = getSelectedOptionText(
    document.getElementById("country")
  )
  document.getElementById("jsEmail").textContent =
    document.getElementById("email").value
  document.getElementById("jsPhone").textContent =
    document.getElementById("phone").value
}
