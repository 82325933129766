export const elements = {
  adf: document.getElementById('adf'),
  amountBtns: document.querySelectorAll('[name="gift-amount"]'),
  appealCode: document.getElementById('appealCode'),
  bbForm: document.getElementById('form1'),
  btnBack: document.getElementById('btnBack'),
  btnCancel: document.getElementById('btnCancel'),
  btnNext: document.getElementById('btnNext'),
  confirmation: document.getElementById('confirmation'),
  country: document.getElementById('country'),
  doubleTheDonation: document.getElementById('dd-container'),
  employer: document.getElementById('employer'),
  employeeId: document.getElementById('employeeId'),
  giftLevels: document.getElementById('giftLevels'),
  giftLevelsContainer: document.querySelector('.gift-levels'),
  gifts: document.getElementById('gifts'),
  giftRecurrence: document.getElementById('giftRecurrence'),
  giftRecurrencePayroll: document.getElementById('giftRecurrencePayroll'),
  giftReview: document.getElementById('giftReview'),
  giftsConfirm: document.getElementById('giftsConfirm'),
  intlFieldsTab: document.getElementById('intlFieldsTab'),
  isMatching: document.getElementById('isMatching'),
  isWriteInFund: document.getElementById('isWriteInFund'),
  isTribute: document.getElementById('isTribute'),
  main: document.getElementById('main'),
  modalFeaturedFund: document.getElementById('modalFeaturedFund'),
  modalRestore: document.getElementById('modalRestore'),
  navPills: document.getElementById('navPills'),
  noResultsFound: document.getElementById('noResultsFound'),
  numInstallments: document.getElementById('numInstallments'),
  otherAmount: document.getElementById('otherAmount'),
  page: document.getElementById('page'),
  payrollSummary: document.getElementById('payrollSummary'),
  panels: document.querySelectorAll('.panel'),
  panelBtns: document.querySelectorAll('#navPills .nav-link'),
  phone: document.getElementById('phone'),
  pledgeError: document.getElementById('pledgeError'),
  searchInput: document.getElementById('searchInput'),
  searchResults: document.getElementById('searchResults'),
  searchResultsCount: document.querySelectorAll('searchResultsCount'),
  searchResultsSelect: document.getElementById('searchResultsSelect'),
  state: document.getElementById('state'),
  summary: document.getElementById('summary'),
  title: document.getElementById('title'),
  totalAmount: document.getElementById('jsTotalAmount'),
  writeInFund: document.getElementById('writeInFund'),
  writeInFundInput: document.getElementById('writeInFundInput'),
}