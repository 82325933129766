import { elements } from "../variables/elements"
import { params } from "../variables/params"
import { selectOptionByText } from "../lib/utils"
import { categoryTabs, unitDropdowns, areaDropdowns, fundDropdowns } from "./funds"

const selectCategory = (dropdown, val) => {
  selectOptionByText(dropdown, val)
}

export const maybePreselectCategory = () => {
  if (params.category) {
    const category = categoryIDs[params.category]

    if (category) {
      const categories = category.category.split('>')
      const headerText = category.headerText
      let backgroundImage = category.backgroundImage

      if (categories.length > 0) {
        categoryTabs[categories[0]].click()
        if (categories[1]) {
          selectCategory(document.getElementById(unitDropdowns[categories[0]]), categories[1])
          if (categories[2]) {
            setTimeout(function() {
              selectCategory(document.getElementById(areaDropdowns[categories[0]]), categories[2])
              if (categories[3]) {
                setTimeout(function() {
                  selectCategory(document.getElementById(fundDropdowns[categories[0]]), categories[3])
                }, 200)
              }
            }, 100)
          }
        }
        if (backgroundImage !== '') {
          backgroundImage = `url(${decodeURI(backgroundImage).trim().replace(/\\/g, '')})`
          elements.main.style.setProperty('--bg-image', backgroundImage)
          elements.main.classList.add('has-background-image')
        }
        if (headerText !== '' && document.querySelector('.header .department-text')) {
          document.querySelector('.header .department-text').textContent = headerText
        }
      }
    }
  }
}