const urlSearchParams = new URLSearchParams(window.location.search);

export const params = {
  appeal: urlSearchParams.get('appeal'),
  category: urlSearchParams.get('c'),
  csId: urlSearchParams.get('csid'),
  featuredFund: urlSearchParams.get('ff'),
  pageBg: urlSearchParams.get('bg'),
  preselectedFund: urlSearchParams.get('f'),
  quickForm: urlSearchParams.get('v'),
  scode: urlSearchParams.get('scode'),
  today: urlSearchParams.get('today'),
  tribute: urlSearchParams.get('tribute'),
}