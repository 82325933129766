import { Collapse } from "bootstrap"
import { showCollapse } from "../ui/collapse"
import { classes } from "../variables/classes"
import { elements } from "../variables/elements"

const options = window.ZU_ADF.options || {}

export const init = () => {
  if (options.enableWriteIn && options.enableSearch) {
    const collapse = new Collapse(elements.writeInFund, {
      toggle: false
    })

    elements.searchInput.addEventListener('focus', e => {
      if (!elements.writeInFund.classList.contains(classes.collapseShowClass)) {
        showCollapse(collapse)
      }
    })
  }
}

export const resetWriteInFund = () => {
  if (options.enableWriteIn) {
    if (elements.isWriteInFund.checked) {
      elements.isWriteInFund.click()
    }
    elements.writeInFundInput.value = ''
  }
}