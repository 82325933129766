import FormPersistence from 'form-persistence'
import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { events } from "../variables/events"
import { state } from "../variables/state"
import { bindGiftEvents, updateGiftAmountTotal } from "../adf/gifts"
import { disableTribute } from "../adf/tribute"
import { goToPanel, getActiveStep } from "../ui/nav"
import { triggerEvent } from "../lib/utils"

const options = window.ZU_ADF.options || {}

export const init = () => {
  // save form whenever a input value changes or a check/radio is clicked
  document.querySelectorAll('.panel:not(.gift-info) input:not([type=hidden]):not([type=checkbox]):not([type=radio]), select, textarea').forEach(el => {
    el.addEventListener('input', saveForm)
  })
  document.querySelectorAll('input[type=checkbox], input[type=radio]').forEach(el => {
    el.addEventListener('click', saveForm)
  })
}

export const clearStorage = (resetFeaturedFund = false) => {
  FormPersistence.clearStorage(elements.bbForm, {
    uuid: 'savedForm',
  })
  localStorage.removeItem('formSaved')
  localStorage.removeItem('activeStep')
  localStorage.removeItem('savedNavPills')
  localStorage.removeItem('gifts')
  localStorage.removeItem('giftsConfirm')
  if (resetFeaturedFund) {
    localStorage.removeItem('featuredFundShown')
  }
}

const restoreApiInputs = () => {
  return new Promise((resolve, reject) => {
    const apiInputs = JSON.parse(localStorage.getItem('api'))

    elements.title.innerHTML = JSON.parse(apiInputs.title)
    elements.title.value = apiInputs.titleValue
    elements.country.innerHTML = JSON.parse(apiInputs.country)
    elements.country.value = apiInputs.countryValue
    elements.state.innerHTML = JSON.parse(apiInputs.state)
    elements.state.value = apiInputs.stateValue
    resolve()
  })
}

const restoreGifts = () => {
  elements.gifts.innerHTML = JSON.parse(localStorage.getItem('gifts'))
  elements.giftsConfirm.innerHTML = JSON.parse(localStorage.getItem('giftsConfirm'))
}

const restoreChecks = () => {
  document.querySelectorAll('input[type="checkbox"]:not([data-trigger]):checked, input[type="radio"]:not([data-trigger]):checked').forEach(el => {
    if (!el.checked) {
      el.click()
    }
    triggerEvent(el, 'click')
  })
}

const restoreTriggers = () => {
  // document.querySelectorAll('.trigger').forEach(el => {
  //   if (el.checked) {
  //     triggerEvent(el, 'click')
  //   }
  // })
}

const maybeDisableTribute = () => {
  if (document.querySelector('.collapse.show input[type=radio][name*=recurrence]:not([value=""]):checked')) {
    disableTribute()
  }
}

const restorePills = (activeStep) => {
  elements.panelBtns.forEach((el, index) => {
    if (index < activeStep) {
      el.parentElement.classList.add(classes.completeClass)
    }
  })
}

const restoreDoubleTheDonation = () => {
  const employer = elements.employer.value
  const ddInput = document.getElementById('dd-input')

  if (employer !== '' && ddInput) {
    doublethedonation.plugin.set_company_name_input(employer)
    setTimeout(() => {
      ddInput.dispatchEvent(events.pressEnter)
    }, 1000)
  }
}

export const restoreForm = () => {
  restoreApiInputs().then(() => {
    const activeStep = localStorage.getItem('activeStep')
    state.getCountries = false
    restoreGifts()
    setTimeout(() => {
      FormPersistence.load(elements.bbForm, {
        uuid: 'savedForm',
        // valueFunctions: valueFunctions
      })
      window.initForm()
      bindGiftEvents()
      updateGiftAmountTotal()
      goToPanel(activeStep ? parseInt(activeStep) : 1)
      restoreChecks()
      restoreTriggers()
      if (options.enableTribute) {
        maybeDisableTribute()
      }
      if (options.enableDoubleTheDonation) {
        restoreDoubleTheDonation()
      }
      restorePills(parseInt(activeStep))
      state.isReturning = true
      state.getCountries = true
    }, 1100)
  })
}

export const saveForm = (step) => {
  const activeStep = step || getActiveStep()
  // TODO: consolidate items
  // let savedHtml = {}

  if (activeStep !== 0) {
    FormPersistence.save(elements.bbForm, {
      uuid: 'savedForm',
    })
    // Save current form HTML
    localStorage.setItem('formSaved', 'true')
    // So featured fund doesn't pop up again
    localStorage.setItem('featuredFundShown', state.featuredFundShown)
    // For opening the same panel donor is on now
    localStorage.setItem('activeStep', (typeof step === 'number' ? step : getActiveStep()))
    // These elements cannot be restored with form-persistence.js
    localStorage.setItem('savedNavPills', JSON.stringify(elements.navPills.innerHTML))
    localStorage.setItem('gifts', JSON.stringify(elements.gifts.innerHTML))
    localStorage.setItem('giftsConfirm', JSON.stringify(elements.giftsConfirm.innerHTML))
    // Save Title, Country and States inputs to avoid going back to the API
    localStorage.setItem('api', JSON.stringify({
      title: JSON.stringify(elements.title.innerHTML),
      titleValue: elements.title.value,
      country: JSON.stringify(elements.country.innerHTML),
      countryValue: elements.country.value,
      state: JSON.stringify(elements.state.innerHTML),
      stateValue: elements.state.value
    }))
  }
}