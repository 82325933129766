export const panels = [{
  id: 'giftInfo',
  backBtn: false,
  cancelBtn: true,
  completedText: 'Add Gift',
  nextButtonHtml: '<span>Next</span><span></span>',
},
{
  id: 'giftReview',
  backBtn: false,
  cancelBtn: false,
  completedText: '',
  nextButtonHtml: '<span>Next</span><span>: Your Info</span>',
},
{
  id: 'yourInfo',
  backBtn: true,
  cancelBtn: false,
  completedText: '',
  nextButtonHtml: '<span>Next</span><span>: Confirm</span>',
},
{
  id: 'confirm',
  backBtn: false,
  cancelBtn: false,
  completedText: '',
  nextButtonHtml: '<span>Make Payment</span><span></span>',
},
]