export let state = {
  campaignActive: false,
  featuredFundShown: false,
  getCountries: true,
  giftIndex: 0,
  isAnotherGift: false,
  isEditingGift: false,
  isReturning: false,
  isTribute: false,
  preselectedFundOnly: false,
  restore: false
}