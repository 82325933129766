import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { state } from "../variables/state"
import { clearOptions, createISOOption, moveOptionToTop, selectOptionByText } from "../lib/utils"

const countryService = typeof BLACKBAUD !== 'undefined' ? new BLACKBAUD.api.CountryService() : null

const buildStateSelect = (states) => {
  clearOptions(elements.state)
  states.forEach(state => {
    createISOOption(elements.state, state)
  })
}

const handleCountryChange = (e) => {
  const onSuccess = (data) => {
    if (data.length > 0) {
      buildStateSelect(data)
      elements.state.closest('[class*=col]').classList.remove(classes.hiddenClass)
    } else {
      elements.state.closest('[class*=col]').classList.add(classes.hiddenClass)
      clearOptions(elements.state)
    }
  }

  const onFail = (errors) => {
    console.log(errors)
  }

  // Don't need API if restoring form
  if (e.isTrusted) {
    countryService.getStates(e.target.value, onSuccess, onFail)
  }
}

const buildCountrySelect = (countries) => {
  countries.forEach(country => {
    createISOOption(elements.country, country)
  })
  // US is default
  moveOptionToTop(elements.country, 'United States')
  selectOptionByText(elements.country, 'United States')
}

export const getCountries = () => {
  const onSuccess = (data) => {
    buildCountrySelect(data)
  }

  const onFail = (errors) => {
    console.log(errors)
  }

  if (countryService && state.getCountries) {
    countryService.getCountries(onSuccess, onFail)
  }
  elements.country.addEventListener('change', handleCountryChange)
}