import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { allFunds } from "../adf/funds"

export const hideGiftLevels = () => {
  elements.giftLevelsContainer.classList.add(classes.hiddenClass)
}

export const showGiftLevels = () => {
  elements.giftLevelsContainer.classList.remove(classes.hiddenClass)
}

const insertGiftLevels = (levels) => {
  elements.giftLevels.innerHTML = levels[0]
}

export const maybeGiftLevels = (guid) => {
  const fund = allFunds.filter(obj => {
    return obj.guid === guid && obj.levels !== ''
  })

  if (fund.length === 1) {
    insertGiftLevels(fund[0].levels)
    showGiftLevels()
  } else {
    hideGiftLevels()
  }
}