import { state } from "../variables/state"
import { processFunds } from "../adf/funds"
import { showPage } from "../ui/page"

const queryService = typeof BLACKBAUD !== 'undefined' ? new BLACKBAUD.api.QueryService() : null
const adfGuids = window.adfGuids || {}

export const getFunds = () => {
  const onSuccess = (data) => {
    console.log(data)
    showPage()
    localStorage.setItem('funds', JSON.stringify(data))
    processFunds(data)
  }

  const onFail = (errors) => {
    console.log(errors)
  }

  if (state.isReturning && localStorage.getItem('funds')) {
    showPage()
    processFunds(JSON.parse(localStorage.getItem('funds')))
  } else if (queryService) {
    queryService.getResults(adfGuids.designationQuery, onSuccess, onFail)
  } else {
    onSuccess({
      "Fields": [{
        "Name": "Lookup ID",
        "Caption": "LookupID"
      },
      {
        "Name": "System record ID",
        "Caption": "GUID"
      },
      {
        "Name": "Public name",
        "Caption": "PublicName"
      },
      {
        "Name": "ADF Main Category Attribute\\Value",
        "Caption": "BBIS_Category"
      },
      {
        "Name": "ADF Main Display Name Attribute\\Value",
        "Caption": "BBIS_DisplayName"
      },
      {
        "Name": "ADF Main Featured Fund Attribute\\Value",
        "Caption": "BBIS_FeaturedFund"
      },
      {
        "Name": "ADF Main Is Active Attribute\\Value",
        "Caption": "BBIS_IsActive"
      },
      {
        "Name": "ADF Main Keywords Attribute\\Value",
        "Caption": "BBIS_Keywords"
      },
      {
        "Name": "ADF Main Levels Attribute\\Value",
        "Caption": "BBIS_Levels"
      },
      {
        "Name": "ADF Main URL Redirect Attribute\\Value",
        "Caption": "BBIS_URLRedirect"
      }
      ],
      "Rows": [{
        "Values": [
          "ATHL000007",
          "8341f1ab-cb09-46df-831a-fdc7e33e3ee5",
          "Baseball",
          "",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "ATHL000045",
          "672ac604-e084-429a-8eed-0b76bbaa675d",
          "Men's Basketball (Fan's Choice) Scholarship",
          "",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "ATHL000103",
          "2ea110c9-dd92-49c6-8e65-c701a10e0989",
          "UCF Athletic Director's Fund",
          "Athletics>General Support",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "ATHL000191",
          "e4b17f41-df28-406f-bc56-ad6918959463",
          "Keep Charging On",
          "Athletics>General Support",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "BHOC000001",
          "cd68f332-0d15-424b-acca-e72ea7c8c193",
          "Burnett Honors College Dean's Excellence Fund",
          "Academics>Burnett Honors College>Academic Excellence",
          "Burnett Honors College Dean's Excellence Fund Display Name Test",
          "",
          "True",
          "KeyWordTest",
          "",
          "https://ucf.edu"
        ]
      },
      {
        "Values": [
          "BHOC000029",
          "8a9cf0a5-5bc6-49d5-bdb2-b25cd677dd41",
          "International Service Learning Fund",
          "Academics>Burnett Honors College>Academic Excellence",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "BHOC000038",
          "82fef30b-5ea3-46c5-91c8-637e675267d8",
          "Burnett Honors College Research Fund",
          "Academics>Burnett Honors College>Academic Excellence",
          "",
          "",
          "True",
          "",
          "<em>Levels</em> <strong>Test</strong>",
          ""
        ]
      },
      {
        "Values": [
          "BHOC000039",
          "54b510fd-569f-4a2b-a4a2-7453780a2794",
          "Elevations Scholars Program Fund",
          "Academics>Burnett Honors College>Schools and Programs",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "BHOC000045",
          "95a81d87-9634-40d7-a492-f70a3f00f6f9",
          "Dr. John C. Hitt and Dr. Martha H. Hitt Endowed Scholarship Fund",
          "Academics>Burnett Honors College>Scholarships",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "PROV000024",
          "1e36cfc2-3e1b-4b18-a089-8ff1fa28689d",
          "Faculty Excellence",
          "",
          "",
          "True",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "SDES000015",
          "ab96f94c-b4ca-46d9-abe7-f4844aa7ca0f",
          "Lead Scholars Program General",
          "Student Experience>Campus Life>LEAD Scholars Academy",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "SDES000030",
          "deae44e6-7f0c-45a4-a014-a1f7688bef5c",
          "Lead Scholars Scholarship Program",
          "Student Experience>Campus Life>LEAD Scholars Academy",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "SDES000046",
          "379aec3c-8144-460b-8fdb-56dc78540d20",
          "LGBTQ Services",
          "Student Experience>Campus Life>Diversity, Equity and Inclusion",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "SDES000372",
          "46bb3d1c-923f-4e31-9d15-a9ce71c1c9c4",
          "Office of Fraternity and Sorority Life",
          "Student Experience>Campus Life>Greek Life",
          "",
          "",
          "True",
          "",
          "",
          ""
        ]
      },
      {
        "Values": [
          "UCFF000266",
          "a5b8d32e-8f30-4597-9590-ec4183645013",
          "Area of Greatest Need",
          "",
          "",
          "True",
          "True",
          "",
          "",
          ""
        ]
      }
      ]
    })

    showPage()
  }
}