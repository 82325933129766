import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { selectors } from "../variables/selectors"
import { state } from "../variables/state"
import { restoreAmount } from "./amount"
import { maybeAddAppeal } from "./appeals"
import { maybeGiftLevels, hideGiftLevels } from "./giftLevels"
import { getGiftIndex } from "./gifts"
import { maybePreselectCategory } from "./preselectCategory"
import { maybePreselectFunds } from "./preselectFunds"
import { fundSearch, resetSearch } from "./search"
import { fv } from "../form/validation"
import * as utils from "../lib/utils"

const options = window.ZU_ADF.options || {}

const academicCategory = 'Academics'
const athleticsCategory = 'Athletics'
const studentCategory = 'Student Experience'
const featuredCategory = 'Featured'

export let allFunds = []
let featuredFunds = []
let displayFunds = []
let fundUnits = {}
let fundAreas = {}
let funds = {}
let categoryGroups = []

export let categoryTabs = {
  'Academics': document.getElementById('academicsTab'),
  'Athletics': document.getElementById('athleticsTab'),
  'Student Experience': document.getElementById('studentExperienceTab'),
}

export const fundCategories = {
  'Academics': 'Academics',
  'Athletics': 'Athletics',
  'Student Experience': 'Student Experience',
  'Featured': 'Featured',
}

export const unitDropdowns = {
  'Academics': 'unitSelectAcademics',
  'Athletics': 'unitSelectAthletics',
  'Student Experience': 'unitSelectStudentExperience',
}

export const areaDropdowns = {
  'Academics': 'areaSelectAcademics',
  'Athletics': 'areaSelectAthletics',
  'Student Experience': 'areaSelectStudentExperience',
}

export const fundDropdowns = {
  'Academics': 'fundSelectAcademics',
  'Athletics': 'fundSelectAthletics',
  'Student Experience': 'fundSelectStudentExperience',
  'Featured': 'fundSelectFeatured',
}

const giftUnits = document.querySelectorAll('.unit-select')
const giftAreas = document.querySelectorAll('.area-select')
const giftFunds = document.querySelectorAll('.fund-select')
const searchFunds = document.querySelector('.fund-search-select')

let selectedUnit

const restoreDropdown = (dropdown, val) => {
  utils.selectOption(dropdown, val)
}

const restoreDropdowns = (gift, cat) => {
  const unitSelect = cat.querySelector('.unit-select')
  const areaSelect = cat.querySelector('.area-select')
  const fundSelect = cat.querySelector('.fund-select')

  if (unitSelect && gift.querySelector('.gift-unit').value !== '') {
    restoreDropdown(unitSelect, gift.querySelector('.gift-unit').value)
  }
  if (areaSelect && gift.querySelector('.gift-area').value !== '') {
    restoreDropdown(areaSelect, gift.querySelector('.gift-area').value)
    utils.getClosestEl(areaSelect, '.row').classList.remove(classes.hiddenClass)
  }
  if (fundSelect && gift.querySelector('.gift-fund').value !== '') {
    restoreDropdown(fundSelect, gift.querySelector('.gift-fund').value)
    utils.getClosestEl(fundSelect, '.row').classList.remove(classes.hiddenClass)
  }
}

const restoreCategory = (category) => {
  category.click()
}

const restoreSpecifiedDesignation = (gift) => {
  elements.writeInFund.classList.remove(classes.hiddenClass)
  elements.isWriteInFund.click()
  elements.writeInFundInput.value = gift.querySelector('.gift-name').textContent
}

const restoreDesignationSearch = (gift) => {
  elements.searchInput.value = gift.querySelector('.gift-name').innerText
  utils.triggerEvent(el, 'keyup')
  setTimeout(() => {
    elements.searchResultsSelect.value = gift.querySelector('.gift-fund').value
  }, 1000)
}

const getCategory = (gift) => {
  return Array.from(document.querySelectorAll('.funds .tab-pane')).filter(el => {
    return el.dataset.category === gift.querySelector('.gift-category').value
  })
}

const getCategoryBtn = (gift) => {
  return Array.from(document.querySelectorAll('.category')).filter(el => {
    return el.textContent === gift.querySelector('.gift-category').value
  })
}

export const restoreFund = (el) => {
  const gift = utils.getClosestEl(el, '.gift')
  const cat = getCategory(gift)[0]
  const catBtn = getCategoryBtn(gift)[0]

  state.giftIndex = getGiftIndex(gift)

  if (gift.querySelector('.fund-search').value === 'true') {
    restoreDesignationSearch(gift)
  } else if (gift.querySelector('.fund-specified').value === 'true') {
    restoreSpecifiedDesignation(gift)
  } else {
    restoreCategory(catBtn)
    restoreDropdowns(gift, cat)
  }
  maybeGiftLevels(gift.querySelector('.gift-fund').value)
  restoreAmount(gift)
}

export const resetFunds = () => {
  // Reset select values
  document.querySelectorAll('.unit-select, .area-select, .fund-select').forEach(el => {
    el.value = ''
  })

  // Hide cascading selects
  document.querySelectorAll('.funds .tab-pane:not(#featured) .row:not(.nav):not(:first-child)').forEach(el => {
    el.classList.add(classes.hiddenClass)
  })
}

export const resetCategories = () => {
  document.querySelectorAll('.category, .gift-info .tab-pane').forEach(el => {
    el.classList.remove(classes.activeClass)
  })

  // Activate first category tab
  document.querySelector('.category').click()
}

export const initCategoryBtns = () => {
  document.querySelectorAll('.category').forEach(el => {
    el.addEventListener('click', e => {
      resetFunds()
      hideGiftLevels()
    })
  })
}

const hideFunds = (type) => {
  const allNotFeaturedSubFunds = Array.prototype.slice.call(giftAreas).concat(Array.prototype.slice.call(giftFunds)).filter(el => {
    return el.id !== 'fundSelectFeatured'
  })
  const notFeaturedFunds = Array.prototype.slice.call(giftFunds).filter(el => {
    return el.id !== 'fundSelectFeatured'
  })
  const els = type ? (type === 'area' ? giftAreas : notFeaturedFunds) : allNotFeaturedSubFunds

  els.forEach(el => {
    utils.getClosestEl(el, selectors.fieldSelector).classList.add(classes.hiddenClass)
    utils.selectOption(el, '')
  })
}

const createFundOrAreaDropdown = (field, dropdown, els) => {
  utils.createDropdown(field, dropdown, els)
  setTimeout(() => {
    fv.resetField(dropdown.name)
    field.classList.remove(classes.hiddenClass)
  }, 250)
}

const showAreasOrFunds = (dropdown) => {
  const dropdownValue = dropdown.value
  let fundType = fundAreas[dropdownValue] ? (fundAreas[dropdownValue].length > 0 ? 'area' : '') : ''
  let nextDropdown

  fundType = fundType || (funds[dropdownValue] ? (funds[dropdownValue].length > 0 ? 'fund' : '') : '')

  if (dropdown.classList.contains('unit-select')) {
    selectedUnit = utils.getSelectedOptionValue(dropdown)
  }

  if (fundType === 'area' && !dropdown.classList.contains('area-select')) {
    hideFunds()
    nextDropdown = utils.getClosestEl(dropdown, selectors.fieldSelector).nextElementSibling
    createFundOrAreaDropdown(nextDropdown, nextDropdown.querySelector('select'), fundAreas[dropdownValue])
  } else if (fundType === 'fund' || dropdown.classList.contains('area-select')) {
    if (fundAreas[selectedUnit]) {
      if (fundAreas[selectedUnit].length > 0) {
        hideFunds('fund')
      } else {
        hideFunds('area')
      }
    }
    nextDropdown = utils.getClosestEl(dropdown, selectors.fieldSelector).nextElementSibling.nextElementSibling || utils.getClosestEl(dropdown, selectors.fieldSelector).nextElementSibling
    createFundOrAreaDropdown(nextDropdown, nextDropdown.querySelector('select'), funds[dropdownValue])
  }
}

const handleFundChange = e => {
  resetSearch()
  hideGiftLevels()
  if (e.target.value !== '') {
    showAreasOrFunds(e.target)
  }
}

export const bindFundEvents = () => {
  if (giftUnits.length > 0 && giftAreas.length > 0) {
    Array.prototype.slice.call(giftUnits).concat(Array.prototype.slice.call(giftAreas)).forEach(el => {
      el.addEventListener('change', handleFundChange)
    })

    Array.prototype.slice.call(giftFunds).concat(options.enableSearch ? Array.prototype.slice.call(searchFunds) : []).forEach(el => {
      el.addEventListener('change', e => {
        const selectedFund = utils.getSelectedOptionValue(e.currentTarget)
        // const redirectUrl = utils.getSelectedOptionRedirect(e.currentTarget)

        // if (redirectUrl) {
        //   utils.redirect(redirectUrl);
        // } else if (options.enableGiftLevels) {
        //   maybeGiftLevels(selectedFund);
        // }

        if (options.enableGiftLevels) {
          maybeGiftLevels(selectedFund)
        }
      })
    })
  }
}

const addGroupedOptions = (dropdown, groups) => {
  for (let group in groups) {
    utils.createOptGroup(dropdown, group, groups[group])
  }
}

const getGroups = (funds) => {
  let groups = {}

  funds.forEach(obj => {
    const group = obj.categories[obj.categories.length - 1]

    if (!groups[group]) {
      groups[group] = []
    }
    groups[group].push(obj)
  })
  return groups
}

const addGroupedFeaturedFunds = (category, funds) => {
  const groupedFunds = funds.filter(obj => {
    return obj.categories[1]
  })

  const groups = getGroups(groupedFunds)
  addGroupedOptions(document.getElementById(fundDropdowns[category]), groups)
}

const addOptions = (dropdown, opts) => {
  opts.forEach(el => {
    return typeof el === 'string' ? utils.createOption(dropdown, el) : utils.createDesignationOption(dropdown, el)
  })
}

const addFeaturedFunds = (category, funds) => {
  const looseFunds = funds.filter(obj => {
    return !obj.categories[1]
  })

  if (fundDropdowns[category]) {
    addOptions(document.getElementById(fundDropdowns[category]), looseFunds)
  }
}

const populateFeaturedDropdown = (category, funds) => {
  if (fundDropdowns[category]) {
    addFeaturedFunds(category, funds)
    addGroupedFeaturedFunds(category, funds)
  }
}

const getFunds = (categories) => {
  categories.forEach(obj => {
    const area = obj.categories[obj.categories.length - 1]

    if (area) {
      if (!funds[area]) {
        funds[area] = []
      }
      funds[area].push(obj)
    }
  })

  for (let fund in funds) {
    if (Array.isArray(funds[fund])) {
      funds[fund].sort(utils.dynamicSort('name'))
    }
  }
}

const getAreas = (category, units) => {
  units.forEach(unit => {
    fundAreas[unit] = []

    const hasArea = categoryGroups[category].filter(obj => {
      return obj.categories[2] && obj.categories[0] !== featuredFunds
    })

    hasArea.forEach(obj => {
      const area = obj.categories[2]

      if (obj.categories[1] === unit && fundAreas[unit].indexOf(area) === -1) {
        fundAreas[unit].push(area)
      }
    })

    if (Array.isArray(fundAreas[unit])) {
      fundAreas[unit].sort()
    }
  })
}

const populateUnitDropdown = (category, units) => {
  let dropdown = null

  if (unitDropdowns[category]) {
    dropdown = document.getElementById(unitDropdowns[category])
    if (dropdown.querySelectorAll('option').length === 1) {
      addOptions(dropdown, units)
    }
  }
}

const getUnits = (category, categories) => {
  fundUnits[category] = []
  categories.forEach(obj => {
    const unit = obj.categories[1]

    if (unit && fundUnits[category].indexOf(unit) === -1) {
      fundUnits[category].push(unit)
    }
  })

  if (Array.isArray(fundUnits[category])) {
    fundUnits[category].sort()
    populateUnitDropdown(category, fundUnits[category])
    getAreas(category, fundUnits[category])
  }
}

const getCategoryGroups = () => {
  categoryGroups = displayFunds.reduce((arr, obj) => {
    arr[obj.categories[0]] = [...arr[obj.categories[0]] || [], obj]
    return arr
  }, {})
}

const getFundCategories = () => {
  Array.prototype.slice.call(allFunds).concat(Array.prototype.slice.call(displayFunds)).forEach(obj => {
    let categories = obj.category.split('>')

    if (categories.length > 2) {
      categories[2] = `${categories[1]}>${categories[2]}`
    }
    obj.categories = categories
  })
}

const getDisplayFunds = () => {
  displayFunds = allFunds.filter(obj => {
    return (
      obj.category !== '' && (
        obj.category.indexOf(academicCategory) > -1 ||
        obj.category.indexOf(athleticsCategory) > -1 ||
        obj.category.indexOf(studentCategory) > -1 ||
        obj.category.indexOf(featuredCategory) > -1
      )
    )
  })
}

const cleanFunds = () => {
  allFunds = allFunds.filter(obj => {
    return obj.hasOwnProperty('lookupId') && obj.hasOwnProperty('guid') && obj.hasOwnProperty('name')
  })
  featuredFunds = featuredFunds.filter(obj => {
    return obj.hasOwnProperty('lookupId') && obj.hasOwnProperty('guid') && obj.hasOwnProperty('name')
  })
}

const getGiftLevels = (guid, data) => {
  let levels = null

  const fundGroup = data.Rows.filter(obj => {
    return obj.Values[1] === guid
  })

  if (fundGroup.length > 0) {
    fundGroup.forEach(fund => {
      levels = fund.Values[8].split('\n\n0\n')
    })
  }
  return levels
}

const parseFunds = (data) => {
  return new Promise((resolve, reject) => {
    data.Rows.forEach(el => {
      const isActive = el.Values[6] === 'True'
      let fundValues = {
        category: el.Values[3],
        featured: el.Values[5] === 'True',
        guid: el.Values[1],
        keywords: el.Values[7],
        levels: el.Values[8] !== '' ? (options.enableGiftLevels ? getGiftLevels(el.Values[1], data) : '') : '',
        lookupId: el.Values[0],
        name: el.Values[4] !== '' ? el.Values[4] : el.Values[2],
        redirectURL: el.Values[9],
      }

      if (isActive) {
        allFunds.push(fundValues)
        if (fundValues.featured) {
          fundValues.category = featuredCategory
          featuredFunds.push(fundValues)
        }
      }
    })
    return resolve()
  })
}

export const getFund = (fundId) => {
  const isGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  let fund = null

  if (isGuid.test(fundId)) {
    fund = allFunds.filter(obj => {
      return obj.guid.toLowerCase() === fundId.toLowerCase()
    })
  } else {
    fund = allFunds.filter(obj => {
      const lookupId = (obj.lookupId || obj.altLookupId).toLowerCase()

      return lookupId === fundId.toLowerCase()
    })
  }
  return fund[0]
}

export const processFunds = (data) => {
  parseFunds(data).then(() => {
    allFunds = allFunds.concat(featuredFunds)
    cleanFunds()
    getDisplayFunds()
    getFundCategories()
    getCategoryGroups()
    for (let category in categoryGroups) {
      if (category !== featuredCategory) {
        getUnits(category, categoryGroups[category])
      }
      getFunds(categoryGroups[category])
      if (category === featuredCategory) {
        populateFeaturedDropdown(category, featuredFunds)
      }
    }
    bindFundEvents()
    if (options.enableSearch) {
      fundSearch()
    }
    maybePreselectCategory()
    maybePreselectFunds()
    maybeAddAppeal()
    // if (options.tribute) {
    //   maybeTribute();
    // }
  })
}