import { elements } from "../variables/elements"
import { saveForm } from "../form/persistence"

export const init = () => {
  // const dtdScript = document.createElement('script')
  // dtdScript.type = 'text/javascript'
  // dtdScript.async = true
  // dtdScript.src = 'https://doublethedonation.com/api/js/ddplugin.js'
  // document.getElementsByTagName('head')[0].appendChild(dtdScript)

  // dtdScript.onload = () => {
  //   setTimeout(() => {
  //     if (document.querySelector('.dtd-plugin')) {
  //       const callback = () => {
  //         const doubledonationCompanyName = document.querySelector('[name="doubledonation_company_name"]')
  //         const hasMatchingProgram = document.querySelector('.mg-yes')

  //         // Add a "Select company" checkbox if it has a match gift program
  //         if (doubledonationCompanyName && hasMatchingProgram) {
  //           if (hasMatchingProgram.textContent.indexOf('Yes') > -1) {
  //             addSelectCompanyCheckbox(doubledonationCompanyName.value)
  //           }
  //         } else {
  //           clearEmployerName()
  //         }
  //       }

  //       const observer = new MutationObserver(callback)
  //       const ddInput = document.getElementById('dd-input')

  //       // Disable employer search field autofill
  //       if (ddInput) {
  //         ddInput.setAttribute('autocomplete', 'photo')
  //       }
  //       // Listen for changes to DTD div DOM (search results really)
  //       observer.observe(document.querySelector('.dtd-callout'), {
  //           attributes: false,
  //           childList: true,
  //           subtree: false
  //         })
  //         // elements.employer.addEventListener('blur', handleEmployerChange)
  //       elements.isMatching.addEventListener('click', handleIsMatchingClick)
  //     }
  //   }, 5000)
  // }

  const ddInput = document.getElementById('dd-company-name-input')

  if (window.doublethedonation && ddInput) {
    const callback = () => {
      const selectedCompany = document.querySelector('.dtd-company-selected-name')

      if (selectedCompany) {
        // localStorage.setItem('selectedCompany', selectedCompany.textContent)
        elements.employer.value = selectedCompany.textContent
      } else {
        // localStorage.removeItem('selectedCompany')
        elements.employer.value = ''
      }
      saveForm()
    }
    // initialize
    doublethedonation.plugin.load_streamlined_input()
    // listen for a company selection
    const observer = new MutationObserver(callback)
    observer.observe(document.getElementById('dd-company-name-input'), {
      attributes: false,
      childList: true,
      subtree: true
    })
  }
}

const addSelectCompanyCheckbox = (companyName) => {
  const referenceNode = document.querySelector('.mg-summary + .grid-x')
  let clone = document.getElementById('selectCompanyCheckbox').content.cloneNode(true)

  if (referenceNode) {
    clone.querySelector('#jsCompanyName').textContent = companyName
    clone.querySelector('#selectCompany').addEventListener('click', e => {
      setEmployerName(e.target, companyName)
    })
    referenceNode.parentElement.insertBefore(clone, referenceNode)
  }
}

const handleIsMatchingClick = e => {
  if (!e.target.checked) {
    setTimeout(() => {
      document.querySelector('.dtd-search-input').click()
    }, 500)
  }
}

const clearEmployerName = () => {
  elements.employer.value = ''
}

const setEmployerName = (checkbox, companyName) => {
  if (checkbox.checked) {
    elements.employer.value = companyName
  } else {
    clearEmployerName()
  }
  saveForm()
}