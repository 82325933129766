import { classes } from "../variables/classes"
import { elements } from "../variables/elements"
import { panels } from "../variables/panels"
import { state } from "../variables/state"
import { resetAmount } from "../adf/amount"
import { hideGiftLevels } from "../adf/giftLevels"
import { resetSearch } from "../adf/search"
import { resetWriteInFund } from "../adf/writeInFund"
import { updateSummary } from "../adf/summary"
import * as funds from "../adf/funds"
import { showPage, hidePage } from "../ui/page"
import { fv } from "../form/validation"

const options = window.ZU_ADF.options || {}

export const init = () => {
  handleGoToFirstPanel()
  initBackButton()
  initCancelButton()
  initPanelButtons()
  initReviewButton()
}

const focusFirst = (panel) => {
  const focusable = panel.querySelectorAll('input, select, textarea, [tabindex]:not([tabindex="-1"])')

  if (focusable.length > 0) {
    setTimeout(() => {
      focusable[0].focus()
    }, 1000)
  }
}

const resetNav = () => {
  elements.navPills.querySelectorAll('.nav-item').forEach(el => {
    el.classList.remove(classes.completeClass)
  })
}

const handleGoToFirstPanel = () => {
  window.addEventListener('goToFirstPanel', e => {
    fv.plugins.wizard.currentStep = 0
    updateNextButtonText(0)
    document.querySelector(`[aria-controls="${panels[0].id}"]`).click()
    if (e.resetNav) {
      resetNav()
    }
  })
}

const initBackButton = () => {
  elements.btnBack.addEventListener('click', e => {
    const prevPanel = document.querySelector('.panel.active').previousElementSibling
    let prevPanelBtn = null
    let panelIndex = 0

    if (prevPanel) {
      hidePage()
      prevPanelBtn = document.querySelector(`[aria-controls="${prevPanel.id}"]`)
      panelIndex = Array.prototype.indexOf.call(elements.panels, prevPanel)

      if (prevPanelBtn && elements.panels[panelIndex]) {
        toggleBackButton(panels[panelIndex].backBtn)
        updateNextButtonText(panelIndex)
        // fv.plugins.wizard.currentStep = panelIndex + 1;
        fv.plugins.wizard.currentStep = panelIndex
        prevPanelBtn.click()
        showPage()
        focusFirst(prevPanel)
      }
    }
  })
}

const initCancelButton = () => {
  elements.btnCancel.addEventListener('click', e => {
    const panelBtn = document.querySelector(`[aria-controls="${panels[1].id}"]`).parentElement

    funds.resetCategories()
    funds.resetFunds()
    if (options.enableSearch) {
      resetSearch()
    }
    if (options.enableWriteInFund) {
      resetWriteInFund()
    }
    if (options.enableGiftLevels) {
      hideGiftLevels()
    }
    resetAmount()
    state.isEditingGift = false
    state.isAnotherGift = false
    toggleCancelButton()
    updateNextButtonText(1)
    fv.plugins.wizard.currentStep = 1
    panelBtn.classList.add(classes.activeClass)
    panelBtn.querySelector('.nav-link').click()
    panelBtn.classList.remove(classes.activeClass)
  })
}

export const initPanelButtons = () => {
  setTimeout(() => {
    elements.panelBtns.forEach(el => {
      el.addEventListener('click', e => {
        const panelIndex = Array.prototype.indexOf.call(elements.panels, document.querySelector(e.currentTarget.dataset.bsTarget))

        // User click
        if (e.isTrusted) {
          if (panelIndex === 0) {
            state.isEditingGift = false
            state.isAnotherGift = true
            toggleCancelButton()
          }
          updateNextButtonText(panelIndex)
          toggleBackButton(panels[panelIndex].backBtn)
          fv.plugins.wizard.currentStep = panelIndex
        } else {
          toggleCancelButton(panelIndex)
        }
      })
    })
  }, 1000)
}

const initReviewButton = () => {
  document.querySelectorAll('.btn-review').forEach(el => {
    el.addEventListener('click', e => {
      goToPanel(getPanelIndex('giftReview'))
    })
  })
}

export const toggleBackButton = (displayBackButton) => {
  if (displayBackButton) {
    elements.btnBack.parentElement.classList.remove(classes.hiddenClass)
  } else {
    elements.btnBack.parentElement.classList.add(classes.hiddenClass)
  }
}

export const toggleCancelButton = (index = 0) => {
  const cancel = document.querySelector('.form-submit .cancel')

  if (cancel) {
    if (index === 0 && (state.isAnotherGift || state.isEditingGift)) {
      cancel.classList.remove(classes.hiddenClass)
    } else {
      cancel.classList.add(classes.hiddenClass)
    }
  }
}

export const updateNextButtonText = (index) => {
  document.getElementById('btnNext').innerHTML = panels[index].nextButtonHtml
}

export const updatePillText = (index) => {
  const panel = panels[index - 1]
  const pillText = document.querySelector(`[aria-controls="${panel.id}"]`).querySelector('.pill-text')

  pillText.textContent = panel.completedText !== '' ? panel.completedText : pillText.textContent
}

export const goToNextPanel = (e) => {
  const numSteps = e.numSteps
  const panelIndex = e.index
  const nextPanelBtn = document.querySelector(`[aria-controls="${panels[panelIndex].id}"]`)
  const prevPanelBtn = document.querySelector(`[aria-controls="${panels[panelIndex - 1].id}"]`)

  let prevPanelItem = null

  if (panelIndex < numSteps) {
    hidePage()
  }
  // Go to next panel
  if (nextPanelBtn) {
    nextPanelBtn.click()
  }
  updateSummary()
  //Update nav pill text
  updatePillText(panelIndex)
  //Update next button text
  updateNextButtonText(panelIndex)
  // Might need to show back button
  toggleBackButton(panels[panelIndex].backBtn)
  // Indicate step complete
  if (prevPanelBtn) {
    prevPanelItem = prevPanelBtn.closest('.nav-item')
    if (prevPanelItem) {
      prevPanelItem.classList.add(classes.completeClass)
    }
  }
  showPage()
  focusFirst(document.getElementById(panels[panelIndex].id))
}

export const goToPanel = (index) => {
  const panelBtn = document.querySelector(`[aria-controls="${panels[index].id}"]`)

  hidePage()
  updateSummary()
  fv.plugins.wizard.currentStep = index
  updateNextButtonText(index)
  panelBtn.parentElement.classList.add(classes.completeClass)
  panelBtn.click()
  toggleBackButton(panels[index].backBtn)
  showPage()
  focusFirst(document.getElementById(panels[index].id))
}

export const getActiveStep = () => {
  return Array.prototype.indexOf.call(document.querySelectorAll('.panel'), document.querySelector('.panel.active'))
}